<template>
  <div id="linesDetail">
    <div class="head">
      <div class="titlestyle">边裁仲裁员评价详情</div>
      <!-- <span class="name">年份:</span>
      <el-select v-model="value" placeholder="请选择">
        <el-option v-for="item in options" :key="item" :label="item"
          :value="item">
        </el-option>
      </el-select>
      <el-button type="primary" @click="getList" style="background:#144bd7;margin-left:20px">查询</el-button>
      <el-button @click="reset">重置</el-button> -->
    </div>
    <div style="margin-top:20px">
      <el-table :data="tableData" style="width: 100%;" border>
        <el-table-column prop="case_number" label="案号" width="160px" fixed>
        </el-table-column>
        <el-table-column label="评分" prop="sum_score" width="160px">
        </el-table-column>
        <el-table-column label="配合组庭" prop="cooperation" width="160px">
        </el-table-column>
        <el-table-column label="庭前准备" prop="preparation" width="160px">
        </el-table-column>
        <el-table-column label="庭审纪律" prop="trial_discipline" width="160px">
        </el-table-column>
        <el-table-column label="合议情况" prop="collegiate" width="160px" >
        </el-table-column>
        <el-table-column label="审限期限" width="160px" prop="trial_deadline">
        </el-table-column>
        <el-table-column label="保密工作" width="160px" prop="secrecy">
        </el-table-column>
        <el-table-column label="评价内容" width="160px" prop="remark">
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
import request from "../../../util/request";
import { UiService } from "@/core/services/ui.service";
const ui = new UiService();

let tempTableData;

export default {
  data() {
    return {
      arbiter_id:'',
      tableData: [],
      options: [],
      value: "",
    };
  },
  watch: {

  },
  computed: {},
  async created() {
    this.arbiter_id=this.$route.query.arbiter_id;
    this.value=this.$route.query.year;
    this.getList();

  },
  methods: {
    async getList(){
      let loading=ui.loading();
      let res = await request.getScoreDetail({
        arbiter_role: "边裁仲裁员",
        years:this.value,
        arbiter_id:this.arbiter_id
      });
      loading.close();
      this.options = res.year_list;
      tempTableData=res.list;
      this.tableData=res.list;
    },
    //重置
    reset() {
      this.value = "";
      // this.tableData = tempTableData;
      this.getList();
    },
    
  },
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
#linesDetail {
  .head {
    .titlestyle {
      font-size: 16px;
      color: #4c4c4c;
      margin-bottom: 20px;
    }
    .titlestyle::before {
      content: "";
      width: 4px;
      height: 16px;
      background-color: #144bd7;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      top: 2px;
    }
    .el-input {
      width: 350px;
      height: 40px;
      margin-right: 20px;
    }
    .name {
      font-size: 14px;
      margin-right: 10px;
    }
  }

  ::v-deep {
    .el-table__cell {
      text-align: center;
    }
    .el-table th.el-table__cell{
      background: #f7f7f7;

    }
    .el-table thead.is-group th.el-table__cell {
      background: #f7f7f7;
    }
    .el-table__body-wrapper {
      max-height: calc(100vh - 240px);
      overflow-y: auto;
    }
    .el-table__fixed-body-wrapper {
      max-height: calc(100vh - 240px);
      overflow-y: auto;
    }
    .el-table__header {
      .cell {
        color: #4c4c4c;
      }
    }
  }
}
</style>
