<template>
  <div id="form">
    <div class="top">仲裁员一案一评评价表</div>
    <div class="content">
      <div class="titlestyle">案件基本信息</div>
      <div class="lr" style="margin-top: 15px">
        <div class="left">
          <div>
            <div>案号：</div>
            <div>{{ basicInfo.case_number }}</div>
          </div>
          <div>
            <div>案件审理程序：</div>
            <div>{{ basicInfo.case_procedure }}</div>
          </div>
          <div>
            <div>组庭日期：</div>
            <div>{{ basicInfo.group_creation_date }}</div>
          </div>
          <div>
            <div>案件申请人：</div>
            <div
              class="ellipsis"
              :title="
                basicInfo.applyer_list && basicInfo.applyer_list.length > 50
                  ? basicInfo.applyer_list
                  : ''
              "
            >
              {{ basicInfo.applyer_list }}
            </div>
          </div>
          <!-- <div>
            <div>仲裁员类别：</div>
            <div>{{basicInfo.arbiter_list[0].arbiter_role}}</div>
          </div> -->

          <div>
            <div>仲裁员：</div>
            <div class="arbiterBox">
              <div
                class="txt"
                :class="{ highlight: index == tabIndex }"
                v-for="(item, index) in basicInfo.arbiter_list"
                :key="index"
              >
                {{ item.arbiter_name }}（{{ item.arbiter_role }}）
              </div>
            </div>
          </div>
        </div>
        <div class="right left">
          <div>
            <div>案由：</div>
            <div>{{ basicInfo.case_name }}</div>
          </div>
          <div>
            <div>案件处理结果：</div>
            <div>{{ basicInfo.case_close_type }}</div>
          </div>
          <div>
            <div>仲裁员产生方式：</div>
            <div>
              {{
                basicInfo.arbiter_list &&
                basicInfo.arbiter_list[tabIndex].arbiter_select_type
              }}
            </div>
          </div>
          <div>
            <div>案件被申请人：</div>
            <div
              class="ellipsis"
              :title="
                basicInfo.applyee_list && basicInfo.applyee_list.length > 50
                  ? basicInfo.applyee_list
                  : ''
              "
            >
              {{ basicInfo.applyee_list }}
            </div>
          </div>
        </div>
      </div>
      <div class="titlestyle" style="margin-top: 10px">仲裁员评价</div>
      <el-tabs
        class="mytabs"
        v-model="tabIndex"
        type="card"
        @tab-click="handleClick"
      >
        <el-tab-pane
          v-for="(item, index) in basicInfo.arbiter_list"
          :key="index"
          :label="item.arbiter_role"
          :name="String(index)"
        >
        </el-tab-pane>
        <div v-if="selected_arbi.arbiter_role == '首席仲裁员'">
          <table
            border="1"
            width="1160px"
            style="margin-bottom: 30px"
            align="center"
            bgcolor="#fff"
            bordercolor="#eeeeee"
          >
            <tr>
              <td>配合组庭:</td>
              <td>
                <Rate
                  :info="data"
                  title="配合组庭"
                  subtitle="及时披露回避情形等积极配合组庭情况 3 分"
                  @score="handleScore"
                  data="cooperation"
                  :num="[0, 1, 2, 3]"
                  ref="rate0"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>庭前准备:</td>
              <td>
                <Rate
                  :info="data"
                  title="庭前准备"
                  subtitle="积极配合排庭、认真阅卷等开庭前准备 3 分"
                  @score="handleScore"
                  data="preparation"
                  :num="[0, 1, 2, 3]"
                  ref="rate1"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>庭审纪律：</td>
              <td>
                <Rate
                  :info="data"
                  title="庭审纪律"
                  subtitle="准时到庭并遵守庭审纪律 3 分"
                  data="trial_discipline"
                  @score="handleScore"
                  :num="[0, 1, 2, 3]"
                  ref="rate2"
                >
                </Rate>
              </td>
            </tr>

            <tr>
              <td rowspan="3">庭审质量：</td>
              <td>
                <Rate
                  :info="data"
                  title="庭审质量"
                  subtitle="1. 庭审驾驭能力较好 3 分"
                  data="trial_quality_1"
                  @score="handleScore"
                  :num="[0, 1, 2, 3]"
                  ref="rate3"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="2. 案件事实查清 2 分"
                  data="trial_quality_2"
                  @score="handleScore"
                  ref="rate4"
                  :num="[0, 1, 2]"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="3. 庭审程序到位 2 分"
                  data="trial_quality_3"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate5"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>程序控制：</td>
              <td colspan="2">
                <Rate
                  :info="data"
                  subtitle="程序紧凑不拖沓 3 分"
                  data="procedure_control"
                  @score="handleScore"
                  :num="[0, 1, 2, 3]"
                  ref="rate6"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>合议情况：</td>
              <td>
                <Rate
                  :info="data"
                  title="合议情况："
                  subtitle="发挥主导作用，对案件研究透彻形成自己意见 3 分"
                  data="collegiate"
                  @score="handleScore"
                  :num="[0, 1, 2, 3]"
                  ref="rate7"
                >
                </Rate>
              </td>
            </tr>

            <tr>
              <td rowspan="8">文书质效：</td>
              <td>
                <Rate
                  :info="data"
                  title="文书质量："
                  subtitle="1. 仲裁员本人拟制文书 2 分"
                  data="document_quality_1"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate8"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="2. 合理时间拟制文书 2 分"
                  data="document_quality_2"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate9"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="3. 事实调查无遗漏 2 分"
                  data="document_quality_3"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate10"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="4. 适用法律准确 2 分"
                  data="document_quality_4"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate11"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="5. 证据认定完整 2 分"
                  data="document_quality_5"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate12"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="6. 行文逻辑通顺或文字数字无误 2 分"
                  data="document_quality_6"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate13"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="7. 不存在漏裁或超裁或错裁 2 分"
                  data="document_quality_7"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate14"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="8. 对核阅意见认真对待 3 分"
                  data="document_quality_8"
                  @score="handleScore"
                  :num="[0, 1, 2, 3]"
                  ref="rate15"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>审理期限：</td>
              <td>
                <Rate
                  :info="data"
                  title="保密"
                  subtitle="在审理期限内按时审结，不存在无正当理由超出审理期限或两次及两次以上延长审理期限 6 分"
                  data="trial_deadline"
                  @score="handleScore"
                  ref="rate16"
                  :num="[0, 1, 2, 3, 4, 5, 6]"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>保密工作：</td>
              <td>
                <Rate
                  :info="data"
                  title="保密"
                  subtitle="不存在泄露评议内容等违反保密义务的情况 6 分"
                  data="secrecy"
                  @score="handleScore"
                  ref="rate17"
                  :num="[0, 1, 2, 3, 4, 5, 6]"
                >
                </Rate>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="selected_arbi.arbiter_role == '边裁仲裁员'">
          <table
            border="1"
            width="1160px"
            style="margin-bottom: 30px"
            align="center"
            bgcolor="#fff"
            bordercolor="#eeeeee"
          >
            <tr>
              <td>配合组庭:</td>
              <td>
                <Rate
                  :info="data"
                  title="配合组庭"
                  subtitle="及时披露回避情形等积极配合组庭情况 7 分"
                  @score="handleScore"
                  data="cooperation"
                  :num="[0, 1, 2, 3, 4, 5, 6, 7]"
                  ref="rate0"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>庭前准备:</td>
              <td>
                <Rate
                  :info="data"
                  title="庭前准备"
                  subtitle="积极配合排庭、认真阅卷等开庭前准备 7 分"
                  @score="handleScore"
                  data="preparation"
                  :num="[0, 1, 2, 3, 4, 5, 6, 7]"
                  ref="rate1"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>庭审纪律：</td>
              <td>
                <Rate
                  :info="data"
                  title="庭审纪律"
                  subtitle="准时到庭并遵守庭审纪律 7 分"
                  data="trial_discipline"
                  @score="handleScore"
                  :num="[0, 1, 2, 3, 4, 5, 6, 7]"
                  ref="rate2"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>合议情况：</td>
              <td>
                <Rate
                  :info="data"
                  title="合议情况："
                  subtitle="对案件研究透彻形成自己意见 7 分"
                  data="collegiate"
                  @score="handleScore"
                  :num="[0, 1, 2, 3, 4, 5, 6, 7]"
                  ref="rate3"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>审理期限：</td>
              <td>
                <Rate
                  :info="data"
                  title="保密"
                  subtitle="在审理期限内按时审结，不存在无正当理由超出审理期限或两次及两次以上延长审理期限 11 分"
                  data="trial_deadline"
                  @score="handleScore"
                  ref="rate4"
                  :num="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>保密工作：</td>
              <td>
                <Rate
                  :info="data"
                  title="保密"
                  subtitle="不存在泄露评议内容等违反保密义务的情况 11 分"
                  data="secrecy"
                  @score="handleScore"
                  ref="rate5"
                  :num="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
                >
                </Rate>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="selected_arbi.arbiter_role == '独任仲裁员'">
          <table
            border="1"
            width="1160px"
            style="margin-bottom: 30px"
            align="center"
            bgcolor="#fff"
            bordercolor="#eeeeee"
          >
            <tr>
              <td>配合组庭:</td>
              <td>
                <Rate
                  :info="data"
                  title="配合组庭"
                  subtitle="及时披露回避情形等积极配合组庭情况 3 分"
                  @score="handleScore"
                  data="cooperation"
                  :num="[0, 1, 2, 3]"
                  ref="rate0"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>庭前准备:</td>
              <td>
                <Rate
                  :info="data"
                  title="庭前准备"
                  subtitle="积极配合排庭、认真阅卷等开庭前准备 3 分"
                  @score="handleScore"
                  data="preparation"
                  :num="[0, 1, 2, 3]"
                  ref="rate1"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>庭审纪律：</td>
              <td>
                <Rate
                  :info="data"
                  title="庭审纪律"
                  subtitle="准时到庭并遵守庭审纪律 3 分"
                  data="trial_discipline"
                  @score="handleScore"
                  :num="[0, 1, 2, 3]"
                  ref="rate2"
                >
                </Rate>
              </td>
            </tr>

            <tr>
              <td rowspan="3">庭审质量：</td>
              <td>
                <Rate
                  :info="data"
                  title="庭审质量"
                  subtitle="1. 庭审驾驭能力较好 3 分"
                  data="trial_quality_1"
                  @score="handleScore"
                  :num="[0, 1, 2, 3]"
                  ref="rate3"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="2. 案件事实查清 2 分"
                  data="trial_quality_2"
                  @score="handleScore"
                  ref="rate4"
                  :num="[0, 1, 2]"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="3. 庭审程序到位 2 分"
                  data="trial_quality_3"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate5"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>程序控制：</td>
              <td colspan="2">
                <Rate
                  :info="data"
                  subtitle="程序紧凑不拖沓 3 分"
                  data="procedure_control"
                  @score="handleScore"
                  :num="[0, 1, 2, 3]"
                  ref="rate6"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>案件分析：</td>
              <td colspan="2">
                <Rate
                  :info="data"
                  subtitle="对案件研究透彻形成自己意见 3 分"
                  data="case_analysis"
                  @score="handleScore"
                  :num="[0, 1, 2, 3]"
                  ref="rate7"
                >
                </Rate>
              </td>
            </tr>

            <tr>
              <td rowspan="8">文书质效：</td>
              <td>
                <Rate
                  :info="data"
                  title="文书质量："
                  subtitle="1. 仲裁员本人拟制文书 2 分"
                  data="document_quality_1"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate8"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="2. 合理时间拟制文书 2 分"
                  data="document_quality_2"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate9"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="3. 事实调查无遗漏 2 分"
                  data="document_quality_3"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate10"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="4. 适用法律准确 2 分"
                  data="document_quality_4"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate11"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="5. 证据认定完整 2 分"
                  data="document_quality_5"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate12"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="6. 行文逻辑通顺或文字数字无误 2 分"
                  data="document_quality_6"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate13"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="7. 不存在漏裁或超裁或错裁 2 分"
                  data="document_quality_7"
                  @score="handleScore"
                  :num="[0, 1, 2]"
                  ref="rate14"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>
                <Rate
                  :info="data"
                  subtitle="8. 对核阅意见认真对待 3 分"
                  data="document_quality_8"
                  @score="handleScore"
                  :num="[0, 1, 2, 3]"
                  ref="rate15"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>审理期限：</td>
              <td>
                <Rate
                  :info="data"
                  title="保密"
                  subtitle="在审理期限内按时审结，不存在无正当理由超出审理期限或两次及两次以上延长审理期限 6 分"
                  data="trial_deadline"
                  @score="handleScore"
                  ref="rate16"
                  :num="[0, 1, 2, 3, 4, 5, 6]"
                >
                </Rate>
              </td>
            </tr>
            <tr>
              <td>保密工作：</td>
              <td>
                <Rate
                  :info="data"
                  title="保密"
                  subtitle="不存在泄露评议内容等违反保密义务的情况 6 分"
                  data="secrecy"
                  @score="handleScore"
                  ref="rate17"
                  :num="[0, 1, 2, 3, 4, 5, 6]"
                >
                </Rate>
              </td>
            </tr>
          </table>
        </div>
        <div class="titlestyle" style="margin-top: 10px">评价</div>

        <div class="textbox">
          <textarea
            :disabled="data.status == 2"
            class="input"
            v-model="remark"
            placeholder="请输入评价内容"
          ></textarea>
        </div>
      </el-tabs>

      <el-button type="info" class="disbtn" v-if="data.status == 2"
        >已评价</el-button
      >
      <el-button type="primary" class="post" v-else @click="post"
        >提交</el-button
      >
    </div>
  </div>
</template>
<script>
import Rate from "../common/rate.vue";
import request from "../../util/request";
export default {
  data() {
    return {
      case_id: "",
      arbiter_id: "",

      data: {},
      basicInfo: {
        // arbiter_role: "独任",//边裁仲裁员   首席仲裁员  独任
      },
      tabIndex: "0",

      remark: "",
      selected_arbi: "",
    };
  },
  components: {
    Rate: Rate,
  },
  async created() {
    // console.log(this.$route.query.caseId)
    this.case_id = this.$route.query.caseId || "";
    this.arbiter_id = this.$route.query.arbiterId || "";
    this.getArbiterInfo();
  },
  methods: {
    async getArbiterScore() {
      let res = await request.getArbiterScore({
        case_id: this.case_id,
        arbiter_id: this.selected_arbi.arbiter_id,
        arbiter_role: this.selected_arbi.arbiter_role,
      });
      // console.log(res)
      if (!res) {
        return;
      }
      if (res.status) {
        this.data = res.score_info;
        this.remark = res.score_info.remark;
      }
    },
    async getArbiterInfo() {
      let res = await request.getCrbitrationCaseInfo({
        case_id: this.case_id,
        arbiter_id: this.arbiter_id,
      });
      if (res) {
        this.arbiter_id = res.arbiter_id || "";
        this.basicInfo = res;
      }
      this.handleClick();
    },
    handleClick(res) {
      this.data = {};
      this.remark = "";
      this.selected_arbi = this.basicInfo.arbiter_list[this.tabIndex];
      this.$nextTick(() => {
        this.resetCom();
        this.remark = "";
      });
      this.getArbiterScore();
    },
    resetCom() {
      for (let i = 0; i < 18; i++) {
        // console.log("rate" + i);
        if (!this.$refs["rate" + i]) {
          continue;
        }
        // console.log(this.$refs["rate" + i])
        this.$refs["rate" + i].reset();
      }
    },
    handleScore(e) {
      // console.log("父组件，", e, this.data);
      this.data = Object.assign(this.data, e);
      // console.log(this.data);
    },

    //提交
    async post() {
      for (let i = 0; i < 18; i++) {
        // console.log("rate" + i);
        if (!this.$refs["rate" + i]) {
          continue;
        }
        // console.log(this.$refs["rate" + i])
        this.$refs["rate" + i].required();
      }
      let scoredNum = 0;
      for (const item in this.data) {
        scoredNum++;
      }
      if (
        this.selected_arbi.arbiter_role == "首席仲裁员" ||
        this.selected_arbi.arbiter_role == "独任仲裁员"
      ) {
        if (scoredNum < 18) {
          this.$myConfirm({
            message:
              "提交失败，检测到有评价项未选择评分（标红选项代表未评价）。",
            showcancel: false,
          });
          return;
        }
      } else {
        if (scoredNum < 6) {
          this.$myConfirm({
            message:
              "提交失败，检测到有评价项未选择评分（标红选项代表未评价）。",
            showcancel: false,
          });
          return;
        }
      }

      // console.log("评价",!this.remark,this.remark)
      // if (!this.remark) {
      //   this.$myConfirm({
      //     message: "请输入评价的内容！",
      //   });
      //   return;
      // }

      this.$myConfirm({
        message: "确定要提交吗？",
      }).then(async () => {
        let data = {
          case_id: this.case_id,
          case_number: this.basicInfo.case_name,
          arbiter_id: this.selected_arbi.arbiter_id,
          arbiter_role: this.selected_arbi.arbiter_role,
          arbiter_name: this.selected_arbi.arbiter_name,
          remark: this.remark,
        };
        let temp=JSON.parse(JSON.stringify(this.data));
        data = Object.assign(data, temp);
        // console.log(data);


        let res = await request.addScore(data);
        if (res) {
          this.$message.success("提交成功！");
        }
        this.handleClick();
      });
      
    },
  },
};
</script>
<style scoped lang="scss">
#form {
  background: #eeeeee;
  // overflow: auto;
  // height: 100%;
  padding-bottom: 20px;
  table {
    td {
      padding: 0 20px;
      font-size: 14px;
      color: #4c4c4c;
    }
  }
  .top {
    height: 70px;
    line-height: 70px;
    background: white;
    font-size: 18px;
    padding-left: 20px;
  }
  .content {
    width: 1200px;
    // height: 1000px;
    background: white;
    margin: 20px auto 0;
    padding: 20px;
    box-sizing: border-box;
    .lr {
      display: flex;
      .left {
        width: 50%;
        > div {
          min-height: 40px;
          // line-height: 40px;
          display: flex;
          div:nth-child(1) {
            //  line-height: 40px;
            color: #9f9f9f;
            font-size: 14px;
          }
          div:nth-child(2) {
            color: #4c4c4c;
            font-size: 14px;
            flex: 1;
          }
        }
      }
    }
    .arbiterBox {
      .txt {
        color: #4c4c4c !important;
        font-size: 14px;
      }
      .highlight {
        color: #144bd7 !important;
      }
    }
  }
  .titlestyle {
    font-size: 16px;
    color: #4c4c4c;
    margin-bottom: 5px;
  }
  .titlestyle::before {
    content: "";
    width: 4px;
    height: 16px;
    background-color: #144bd7;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 2px;
  }
  .post {
    width: 180px;
    height: 40px;
    background-color: #144bd7;
    margin: 0 auto;
    display: block;
  }
  .disbtn {
    width: 180px;
    height: 40px;
    cursor: not-allowed;
    margin: 0 auto;
    display: block;
  }
  .textbox {
    margin: 10px 0 50px 0;
    .input {
      width: 1160px;
      height: 111px;
      line-height: 20px;
      border: solid 1px #eeeeee;
      resize: none;
      font-size: 14px;
      padding: 10px;
      box-sizing: border-box;
    }
  }
}
.ellipsis {
  display: -webkit-box;
  /* -webkit-box-orient: vertical; */
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  //超出多少行显示省略号
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.mytabs {
  margin-top: 20px;
}
::v-deep {
  .el-tabs--card > .el-tabs__header {
    border-bottom: none;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border-bottom: 1px solid #e4e7ed;
  }
  .el-tabs__item:hover {
    color: #144bd7;
  }
  .el-tabs__item.is-active {
    color: #144bd7;
  }
}
</style>