<template>
  <div id="contributeList">
    <div class="head">
      <div class="titlestyle">成绩贡献评价列表</div>
      <!-- <span class="name">年份:</span>
      <el-select v-model="value" placeholder="请选择">
        <el-option v-for="item in options" :key="item" :label="item"
          :value="item">
        </el-option>
      </el-select>
      <el-button type="primary" @click="getList" style="background:#144bd7;margin-left:20px">查询</el-button>
      <el-button @click="reset">重置</el-button> -->
    </div>
    <div style="margin-top:20px">
      <el-table :data="tableData" style="width: 100%;" border>
        <el-table-column prop="title" label="评价指标" width="160px" >
        </el-table-column>
        <el-table-column label="评分" prop="sum_score"  min-width="500px">
          <template slot-scope="scope" >
            <div class="question_item" v-for="(val,key,index) in scope.row.question_obj" :key="index">
              <span>{{index+1}}.{{key}}</span>
              <span>{{val}}分</span>
            </div>
          </template>
        </el-table-column>

          <el-table-column  label="评价内容" prop="remark"  width="160px" >
        </el-table-column>


        
  
      </el-table>
    </div>

  </div>
</template>

<script>
import request from "../util/request";
import { UiService } from "@/core/services/ui.service";
const ui = new UiService();

let tempTableData;

export default {
  data() {
    return {
      name: "",
      arbiter_id:'',
      tableData: [
       
      ],
      options: [],
      value: "",
    };
  },
  watch: {

  },
  computed: {},
  async created() {
    this.arbiter_id=this.$route.query.arbiter_id;
    this.value=this.$route.query.year;
    this.getList();



  },
  methods: {
    async getList(){
      let loading=ui.loading();
      let res = await request.getContributeDetail({
        years:this.value,
        arbiter_id:this.arbiter_id
      });
      loading.close();
      if(!res){
        return;
      }
      this.options=res.year_list;
      // let list=res.list.filter(item=>item.train_score_status==1)
      this.tableData=res.list;
    },
    reset() {
      this.value = "";

      this.getList();
    },
  },
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
#contributeList {
  .head {
    .titlestyle {
      font-size: 16px;
      color: #4c4c4c;
      margin-bottom: 20px;
    }
    .titlestyle::before {
      content: "";
      width: 4px;
      height: 16px;
      background-color: #144bd7;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      top: 2px;
    }
    .el-input {
      width: 350px;
      height: 40px;
      margin-right: 20px;
    }
    .name {
      font-size: 14px;
      margin-right: 10px;
    }
  }
  .toDetail{
    color:rgb(20, 75, 215);
    cursor: pointer;
  }
  .question_item{
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 1px solid #ebeef5;
    &:first-child{
      padding-top: 0px;
    }
    &:last-child{
      padding-bottom: 0px;
    border-bottom: none;
    }
  }
  ::v-deep {
    .el-table__cell {
      // text-align: center;
    }
    .el-table th.el-table__cell{
      background: #f7f7f7;

    }
    .el-table thead.is-group th.el-table__cell {
      background: #f7f7f7;
    }
    .el-table__body-wrapper {
      max-height: calc(100vh - 240px);
      overflow-y: auto;
    }
    .el-table__fixed-body-wrapper {
      max-height: calc(100vh - 240px);
      overflow-y: auto;
    }
    .el-table__header {
      .cell {
        color: #4c4c4c;
      }
    }
  }
}
</style>
