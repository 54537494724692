<template>
  <div id="soleDetail">
    <div class="head">
      <div class="titlestyle">独任仲裁员评价详情</div>
      <!-- <span class="name">年份:</span>
      <el-select v-model="value" placeholder="请选择">
        <el-option v-for="item in options" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
      <el-button type="primary" @click="getList" style="background:#144bd7;margin-left:20px">查询
      </el-button>
      <el-button @click="reset">重置</el-button> -->
    </div>
    <div style="margin-top:20px">
      <el-table :data="tableData" style="width: 100%;">
          <el-table-column prop="case_number" label="案号" width="160px" fixed>
        </el-table-column>
        <el-table-column label="评分" prop="sum_score" width="160px" sortable>

        </el-table-column>
        <el-table-column label="配合组庭" prop="cooperation" width="160px">
        </el-table-column>
        <el-table-column label="庭前准备" prop="preparation" width="160px">
        </el-table-column>
        <el-table-column label="庭审纪律" prop="trial_discipline" width="160px">
        </el-table-column>
        <el-table-column label="庭审质量">
          <el-table-column label="庭审驾驭能力较好" prop="trial_quality_1" width="160px">
          </el-table-column>
          <el-table-column label="案件事实查清" prop="trial_quality_2" width="160px">
          </el-table-column>
          <el-table-column label="庭审程序到位" prop="trial_quality_3" width="160px">
          </el-table-column>
        </el-table-column>
        <el-table-column label="程序控制" prop="procedure_control" width="160px" >
        </el-table-column>
        <el-table-column label="案件分析" prop="case_analysis" width="160px" >
        </el-table-column>
        <el-table-column label="文书质效">
          <el-table-column label="本人拟制文书" width="160px" prop="document_quality_1">
          </el-table-column>
          <el-table-column label="合理时间拟制文书" width="160px" prop="document_quality_2">
          </el-table-column>
          <el-table-column label="事实调查无遗漏" width="160px" prop="document_quality_3">
          </el-table-column>
          <el-table-column label="适用法律准确" width="160px" prop="document_quality_4">
          </el-table-column>
          <el-table-column label="证据认定完整" width="160px" prop="document_quality_5">
          </el-table-column>
          <el-table-column label="行文逻辑通顺或文字数字无误" width="160px" prop="document_quality_6">
          </el-table-column>
          <el-table-column label="不存在漏裁或超裁或错裁" width="160px" prop="document_quality_7">
          </el-table-column>
          <el-table-column label="对核阅意见认真对待" width="160px" prop="document_quality_8">
          </el-table-column>
        </el-table-column>
        <el-table-column label="审限期限" width="160px" prop="trial_deadline">
        </el-table-column>
        <el-table-column label="保密工作" width="160px" prop="secrecy">
        </el-table-column>
        <el-table-column label="评价内容" width="160px" prop="remark">
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
import request from "../../../util/request";
import { UiService } from "@/core/services/ui.service";
const ui = new UiService();

let tempTableData;
export default {
  data() {
    return {
      arbiter_id:'',
      tableData: [],
      options: [],
      value: "",
    };
  },
  watch: {
 
  },
  computed: {},
  async created() {
    this.arbiter_id=this.$route.query.arbiter_id;
    this.value=this.$route.query.year;
    this.getList();
   
  },
  methods: {
    async getList(){
      let loading=ui.loading();
      let res = await request.getScoreDetail({
        arbiter_role: "独任仲裁员",
        years:this.value,
        arbiter_id:this.arbiter_id
      });
      loading.close();
      this.options = res.year_list;
      tempTableData=res.list;
      this.tableData=res.list;
    },
    //重置
    reset() {
      this.value = "";
      // this.tableData = tempTableData;
      this.getList();
    }, 
  },
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
#soleDetail {
  .head {
    .titlestyle {
      font-size: 16px;
      color: #4c4c4c;
      margin-bottom: 20px;
    }
    .titlestyle::before {
      content: "";
      width: 4px;
      height: 16px;
      background-color: #144bd7;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      top: 2px;
    }
    .el-input {
      width: 350px;
      height: 40px;
      margin-right: 20px;
    }
    .name {
      font-size: 14px;
      margin-right: 10px;
    }
  }

  ::v-deep {
    .el-table__cell {
      text-align: center;
    }
    .el-table thead.is-group th.el-table__cell {
      background: #f7f7f7;
    }
    .el-table__body-wrapper {
      max-height: calc(100vh - 308px);
      overflow: auto;
    }
    .el-table__fixed-body-wrapper {
      max-height: calc(100vh - 320px);
      overflow: auto;
    }
    .el-table__header {
      .cell {
        color: #4c4c4c;
      }
    }
  }
}
</style>
