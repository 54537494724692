// API网关
// 线下
// const apiHost = 'https://api-saas-sign.firerock.tech';
// const apiHost = 'http://192.168.1.11:17001';
// const apiHost = 'http://192.168.10.3:10050';//
// const apiHost = 'https://yh-test.firerock.tech/wz';//
// const apiHost = 'http://8.136.102.149:17001';
// const apiHost = 'https://api.acwz.org.cn'; 

// const apiHost = 'http://192.168.20.33:10050/api';//本地
// const apiHost = 'https://yh-test.firerock.tech/wz/api';//线下
// const appCode = '7pVav5aLwdzgszN2htpCYupMHpp71nHG'
const apiStage = 'RELEASE';
const apiAppKey = '203890949';
const apiAppSecret = 'Bv2LSuYJy5orvAoKk7Ze5LM1oOr7pOkL';
const templateH5Host = 'https://sign-saas-template.firerock.tech/#/';


// 线上
const appCode = '8qHvJfUhrNANc7zQcONHLbWBVRdwDotD';
//v1
const apiHost = 'https://server-api.acwz.org.cn:10050/api';
//v2
// const apiHost = 'https://server-api-v2.acwz.org.cn:10050/api';

// const apiStage = 'RELEASE';
// const apiAppKey = '203726653';
// const apiAppSecret = '93ui9e2ld0pvcuisj7sa7fewd6ngzu4i'; 
// const templateH5Host = 'https://sig-template.firerock.tech/#/';
// const apiHost = 'https://sig-server-api.firerock.tech:17001' // egg 线上

export { apiHost, apiStage, appCode, templateH5Host, apiAppKey, apiAppSecret };
